.highlighted-card-container {
    /* Estilos específicos para o container de cards em destaque */
    background-color: #900;
    border-radius: 0.8rem;
    padding: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    z-index: -1;
}

.highlighted-rifa-info {
    text-align: left;
    position: relative;
}

.highlighted-rifa-info h2 {
    font-size: 1em;
    color: #FFF;
}

.highlighted-rifa-info p {
    font-size: 0.7em;
    color: #b0b0b0;
}

.highlighted-rifa-card {
    /* Estilos específicos para os cards em destaque */
    border-radius: 0.4rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.highlighted-rifa-card {
    border-radius: 8px;
}

.highlighted-img-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 !important;
    z-index: -1;
}

.highlighted-img-wrapper img {
    height: 100%;
    width: 100%;
    max-width: 145px;
}

.highlighted-rifa-card .rifa-info {
    color: #fff;
}

.highlighted-rifa-card .button-price .rifa-price {
    font-size: 1.1em;
    color: #fff;
}

.highlighted-rifa-card .button-confirm {
    background-color: #FFF;
    border: none;
    color: #900;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.highlighted-rifa-card .button-confirm:hover {
    background-color: #A00;
    color: #FFF;
}

.winner-text {
    display: flex;
    justify-content: start;
    align-items: center;
}

.winner-text b {
    color: #FFF !important;
    padding: 0rem 1rem;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.crown-icon {
    position: absolute;
    left: 2rem;
    top: -2rem;
}

@media (min-width: 768px) {
    .highlighted-card-container {
        max-width: 32% !important;
    }
}