.modal-body {
    background-color: #f0f0f0;
}

.modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.add-button {
    background-color: #FFF !important;
    border: 0 !important;
    color: #900 !important;
    font-size: 1.5rem !important;
    min-width: 100px !important;
    border-radius: 1rem !important;
}

.add-button:hover {
    background-color: #900 !important;
    color: #FFF !important;
}

.icon-ticket-count {
    font-size: 1.2rem;
    color: #900;
    padding: 1rem;
}

.card-ticket-count {
    min-width: 5rem;
}

.youtube-video-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    margin-bottom: 1rem; /* Add some spacing below the video */
}

.youtube-video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 1.5rem;
}

.modal-subtitle {
    font-size: 1rem;
    color: #909090;
}

.btn-primary {
    background-color: #900 !important;
    font-size: 1.2rem !important;
}