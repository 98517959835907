.carousel-container {
    position: relative;
    margin-bottom: 0.5rem;
}

.carousel-container img {
    height: 60svh;
}

.blur img {
    filter: blur(0.5rem) !important;
}


.carousel-cta {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    color: #fff;
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    z-index: 10;
    width: 90%;
}

.carousel-cta p {
    margin: 0;
}

.hero-section {
    text-align: center;
    padding: 2rem 1rem;
    margin-top: 2rem;
    background: #f0f0f0;
    font-size: 1.5rem;
}

.hero-section h1 {
    font-size: 2rem;
}

.steps-section {
    padding: 3rem 1rem;
    background: #ffffff;
    font-size: 1.2rem;
}

.steps-section h2 {
    text-align: center;
    padding-bottom: 2rem;
}

.steps-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.step {
    flex: 1;
    text-align: center;
}

.step h3 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

.cta-section {
    text-align: center;
    padding: 0rem 0rem !important;
    background-color: #f0f0f0;
    font-size: 1.5rem;
    color: #000;
}

.cta-section p {
    padding-bottom: 2svh;
}

.rifas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: #f0f0f0;
    padding: 2rem 0rem 4rem 0rem;
}

.rifas-highlight {
    background-color: #1e1e1e !important;
    color: #FFF;
}

.rifas-highlight h1 {
    font-size: 1.5em;
}

.hero-image-wrapper {
    background-color: #9005;
    padding: 0.5rem;
    border-radius: 3rem;
    margin: 1rem;
    margin-top: 5rem;
}

.hero-image-wrapper img {
    width: 100%;
    border-radius: 3rem;
}

.cta-text {
    background-color: #900000;
    margin: 0 !important;
    padding: 2rem;
    color: #FFF;
}

.cta-text h2 {
    font-size: 4rem;
}

.hero-container {
    background-image: url(../img/headerBackground.png);
}

@media (min-width: 768px) {
    .steps-container {
        flex-direction: row;
        justify-content: space-around;
    }

    .step {
        margin: 0 0.5rem;
    }

    .rifas {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .rifas>* {
        flex: 1 1 calc(50% - 0.5rem);
        margin: 0.25rem;
    }

    .hero-image-wrapper {
        background-color: #9005;
        padding: 0.5rem;
        border-radius: 3rem;
        margin: 1rem;
        margin-top: 5rem;
    }

    .hero-image-wrapper img {
        height: 40svh !important;
    }
}

@media (min-width: 992px) {
    .rifas>* {
        flex: 1 1 calc(33.333% - 0.5rem);
    }
}

@media (max-width: 767px) {
    .carousel-cta {
        display: none;
    }

    .carousel-container img {
        height: auto;
    }

    .hero-section {
        font-size: 1.5rem;
    }

    .hero-section h1 {
        font-size: 2rem;
    }

    .steps-section {
        font-size: 1.2rem;
    }

    .step h3 {
        font-size: 1.5rem;
    }

    .cta-section {
        font-size: 1.5rem;
    }

    .rifas {
        padding: 2rem 1rem;
    }

    .image-before-footer img {
        width: 90% !important;
    }
}


.image-before-footer {
    padding: 3rem 0rem;
    text-align: center;
    background-color: #f0f0f0;
}

.image-before-footer img {
    width: 50%;
}