@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Oswald:wght@200;300;400;500;600;700&display=swap");

@keyframes pulse {
  0% {
  box-shadow: 0 0 0 0 #25d366;
  }
  80% {
  box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
  }
}

@keyframes pulse-2 {
  0% {
  box-shadow: 0 0 0 0 #c2030389;
  }
  80% {
  box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
  }
}

body {
  margin: 0;
  font-family: "Oswald", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  width: 100%;
  height: auto;
  margin-top: 2rem;
}

.header-bg {
  background-image: url("../img/headerBackground.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 40rem;
}

.header-title {
  margin-top: 10rem;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 3rem;
}

.la-casa-bg {
  background-color: #c20303;
  background-image: url('../img/headerBackground.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.card-home {
  background-color: #910202 !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px !important;
  width: 70vw !important;
  margin: 6rem 0;
  z-index: 10;
}

.cta-home {
  margin: 4rem 1rem;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #c20303;
  line-height: 2.5rem;
}

.cta-home span {
  background-image: linear-gradient(to right, #2b0101, #ff0000);
  color: #f7f7f7;
  padding: 0 0.5rem;
  display: inline-block;
  margin: 0.3rem 0;
}

.text-highlight {
  background-image: linear-gradient(to right, #2b0101, #ff0000);
  color: #f7f7f7 !important;
  padding: 0 0.5rem;
  display: inline-block;
  margin: 0.3rem 0;
}

.value-ticket {
  background-color: #f7f7f7;
  color: #c20303;
  font-weight: 700;
  padding: 0.5rem 1.3rem;
  display: inline-flex;
  justify-content: start;
  border-radius: 15px;
  font-size: 1.5rem;
  flex-direction: column;
}

.hint-qrcode {
  padding: 1rem 5rem;
}

.loader-qrcode {
  width: 5rem !important;
  height: 5rem !important;
  color: #c20303 !important;
}

.loader-container {
  padding: 2rem 0;
}

.loader-container p {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #c20303 !important;
}

.btn-copy {
  background-color: #c20303 !important;
  color: #f7f7f7 !important;
  text-transform: uppercase;
  cursor: pointer;
}

.value-ticket span {
  font-size: 1rem;
  border-bottom: solid 2px #c20303;
  padding: 0 !important;
  text-transform: uppercase;
}

.card-home .title {
  text-align: left;
  color: #f7f7f7;
  margin-left: 0.5rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.card-home .title span{
  margin-left: 0.5rem;
}

.card-home .container {
  text-align: left;
  padding: 1rem 0.5rem;
}

.card-home .container .value {
  background-color: #f5f5f5;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  padding: 1rem 1.5rem;
  width: 100%;
  transition: transform 0.3s ease-in-out !important;
  animation-name: pulse-2;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

.card-home .container .value a{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c20303 !important;
  font-size: 1.1rem;
}

.card-home .container .value a span{
  margin-left: 0.5rem;
}

.card-home .container .value .inner {

}

.card-home .container .value .inner h6{
  display: block;
  color: #c20303;
  margin: 0;
  text-transform: uppercase;
  border-bottom: solid 1px #c20303;
}

.card-home .container .value .inner span{
  font-weight: 600;
  font-size: 1.7rem;
  color: #c20303 !important;
}

.card-home .container h5 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  color: #f7f7f7;
}

.card-home .container p {
  font-family: "Josefin Sans", sans-serif !important;
  font-size: 1.5rem;
  color: #f7f7f7;
}

.card-details {
  margin-top: -21rem;
  background-image: url("../img/backgroundInfo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2rem !important;
  overflow: hidden;
}

.card-info-container {
  text-align: left;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
}


.card-info-container h5 {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 600;
  color: #f7f7f7;
}

.card-info-container p {
  font-family: "Josefin Sans", sans-serif !important;
  font-size: 1.5rem;
  color: #f7f7f7;
}

.cta-valor-cota {
  margin-bottom: 2rem;
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #c20303;
}

.cta-valor-cota span {
  display: inline-flex;
  background-color: #242424;
  color: #f7f7f7;
  padding: 0.3rem 0.5rem;
}

.bg-gold-bars {
  position: relative;
  background-image: url("../img/cotasBackground.png");
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-gold-bars::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* cor preta com opacidade de 50% */
  z-index: 1;
}


.bg-select-cotas {
  background-image: url("../img/cotasBackground.png");
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
}

.time-hint {
  font-size: 1.5rem;
  font-weight: 600;
  color: #c20303;
}

.ticket-selection-btn {
  background-color: #c20303 !important;
  transform: scale(1);
  transition: transform 0.3s ease-in-out !important;
  background-image: url("../img/backgroundCard.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 10rem;
  height: 10rem;
  border-radius: 25px !important;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ticket-selection-btn:hover {
  transform: scale(1.1); /* Aumenta o elemento em 10% */
}

.qtd-ticket {
  display: block;
  font-size: 2.5rem;
}

.desc-ticket {
  display: block;
  font-family: "Josefin Sans", sans-serif !important;
  font-size: 0.6rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
}

.icon-ticket {
  display: inline-flex;
  background-color: #f7f7f7;
  color: #242424;
  width: 2.2rem;
  height: 2.2rem;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border-radius: 50%;
  font-size: 1.5rem;
}

.select-cotas-container {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.selected-tickets-counter {
  display: flex;
  background-image: linear-gradient(to right, #f5f5f5, #f5f5f5);
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  height: 4rem;
}

.selected-tickets-counter input {
  background-color: transparent;
  border: none !important;
  text-align: center;
  color: #242424;
  font-weight: 700;
  font-size: 2rem;
  height: 4rem;
}

/* Estiliza o input para remover a borda */
.selected-tickets-counter input[type="number"] {
  border: none; /* Remove a borda */
  outline: none; /* Remove o contorno que aparece ao focar */
}

/* Esconde os botões de incremento e decremento em navegadores que usam o Webkit como motor de renderização */
.selected-tickets-counter input[type="number"]::-webkit-inner-spin-button,
.selected-tickets-counter input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Para o Firefox, você pode precisar de um estilo adicional para esconder os botões */
.selected-tickets-counter input[type="number"] {
  -moz-appearance: textfield;
}

.selected-tickets-counter button {
  border-radius: 50%;
  padding: 0;
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #f5f5f5;
  background-color: #c20303;
}

.icon-pending{
  color: #c20303;
  padding: 1rem 0;
  font-size: 10rem;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.pending-warning {
  font-size: 1.3rem;
}

.coming-soon-card {
  background-image: linear-gradient(to right, #2b0101, #ff0000);
  color: #f7f7f7;
  border-radius: 30px !important;
}

.coming-soon-card h1{
  font-size: 1.9rem;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}

.success-hint {
  margin: 1rem 0;
  margin-bottom: 2rem;
  font-size: 1.3rem;
}

.success-container {
  flex-direction: column;
}

.success-container button {
  margin-bottom: 3rem;
  border: none;
  padding: 0.5rem 1rem;
  background-color: #c20303;
  color: #f7f7f7;
  text-transform: uppercase;
  border-radius: 10px;
  font-weight: 700;
}

.success-container .text {
  font-size: 1.5rem;
}

.success-container .icon {
  font-size: 1.6rem;
  margin-right: 0.5rem;
}

.btn-buy-link {
  border: none;
  color: #f7f7f7 !important;
  padding: 1rem 2rem;
  background-image: linear-gradient(to right, #2b0101, #ff0000);
  font-size: 2rem !important;
  border-radius: 15px !important;
  transform: scale(1);
  transition: transform 0.3s ease-in-out !important;
  animation-name: pulse-2;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

.btn-buy {
  background-image: linear-gradient(to right, #2b0101, #ff0000);
  font-size: 2rem !important;
  border-radius: 15px !important;
  transform: scale(1);
  transition: transform 0.3s ease-in-out !important;
  animation-name: pulse-2;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

.btn-buy:hover {
  transition: transform 0.3s ease-in-out !important;
  transform: scale(1.1);
}

.shadow-light:hover {
  -webkit-box-shadow: 0px 0px 21px 2px rgba(255, 255, 255, 1) !important;
  -moz-box-shadow: 0px 0px 21px 2px rgba(255, 255, 255, 1) !important;
  box-shadow: 0px 0px 21px 2px rgba(255, 255, 255, 1) !important;
}

.accordion-button:not(.collapsed) {
  color: #c20303 !important;
}

.gold-bar{
  width: 500px;
  padding: 0;
  margin: -10rem 0;
}

.faq-header h2 {
  font-size: 2.5rem;
  text-align: center;
  margin-top: 4rem;
  padding: 0.7rem 1rem;
  text-transform: uppercase;
  color: #242424;
}

.faq-answer {
  font-family: "Josefin Sans", sans-serif !important;
}

.second-font {
  font-family: "Josefin Sans", sans-serif !important;
}

.faq-container {
  padding-top: 0.5rem;
  padding-bottom: 5rem;
}

.footer-container {
  background-image: url("../img/rodapeBackground.png") !important;
  background-repeat: repeat-x;
  background-position: center;
  background-size: cover;
  min-height: 20rem;
  padding-top: 10rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden !important;
  background-color: #f0f0f0;
}

.footer-container h5 {
  color: #f7f7f7 !important;
  font-size: 1.5rem;
}

.footer-container img {
  margin: 1.5rem 0;
}

.footer-icons-container {
  display: inline-flex;
  justify-content: start;
  align-items: center !important;
  font-size: 1.8rem;
  background-color: #f7f7f7;
  padding: 0.7rem 1.3rem;
  border-radius: 20px;
  margin: 1.5rem 0;
}

.footer-icons-container h5 {
  display: block;
  color: #242424 !important;
}

.footer-icons-container a {
  transform: scale(1);
  transition: transform 0.3s ease-in-out !important;
  margin: 0 0.5rem;
  color: #c20303 !important;
}

.footer-icons-container a:hover {
  transform: scale(1.1);
}

.float-element {
  z-index: 300 !important;
  overflow: visible;
}

.float-element img {
  width: 450px !important;
  height: auto;
  position: relative;
}

.float-element.left img {
  top: -21rem;
  left: 4rem;
}

.float-element.right img {
  top: -9rem !important;
  right: 12rem !important;
  width: 450px !important;
}

.custom-navbar {
  margin-top: 1rem;
  background-color: transparent;
  position: fixed !important;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}

.custom-navbar-container {
  background-color: #c203038e !important;
  width: 90% !important;
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;
  border-radius: 40px;
  padding: 1rem 4rem;
}

.custom-navbar-container img {
  width: 140px;
}

.custom-navbar-buttons-container {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.custom-navbar-buttons-container button {
  margin: 0 0.3rem;
  border: none;
  border-radius: 5px;
  background-color: #f7f7f7;
  color: #c20303;
  font-size: 1rem;
  padding: 0.3rem 0.7rem;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.custom-navbar-icon {
  display: inline;
  margin-right: 0.3rem;
  font-size: 1.2rem;
}

.custom-navbar-text {
  display: inline;
  font-size: 0.9rem;
}

.thanks-page-container {
  background-color: #e4e4e4 !important;
  min-height: 100vh;
  padding: 3rem 1rem;
  padding-top: 5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.thanks-page-container h1 {
  display: inline-flex;
  background-color: #c20303;
  padding: 1rem 1.5rem;
  color: #f7f7f7;
  text-transform: uppercase;
  margin-top: 3rem;
}

.icon-obrigado {
  color: #c20303;
  padding: 4rem 0;
  font-size: 20rem;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.logo-thanks-page {
  width: 30%;
}

.custom-navbar-thanks {
  margin: 0 !important;
  background-color: transparent;
  position: fixed !important;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  background-color: #e4e4e4;
  border-bottom: solid 1px #c20303;
}

.bkg-default {
  background-color: #c20303 !important;
  color: #f7f7f7 !important;
}

.bkg-default-outline {
  background-color: transparent !important;
  border: solid 1px #c20303 !important;
  color: #c20303 !important;
}

.bkg-default-secondary {
  background-color: #c2030380 !important;
  color: #f7f7f7 !important;
}

.custom-navbar-container-thanks {
  width: 100%;
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;
  border-radius: 40px;
  padding: 1rem ;
}

.custom-navbar-container-thanks img {
  width: 140px;
}

.custom-navbar-logo-container-thanks {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.custom-navbar-logo-container-thanks p{
  padding-left: 0.5rem;
  margin-bottom: 0 !important;
}

.custom-navbar-buttons-container-thanks {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.custom-navbar-buttons-container-thanks button {
  margin: 0 0.3rem;
  border: none;
  border-radius: 5px;
  background-color: #f7f7f7;
  color: #c20303;
  font-size: 1rem;
  padding: 0.3rem 0.7rem;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.custom-navbar-icon-thanks {
  display: inline;
  margin-right: 0.3rem;
  font-size: 1.2rem;
}

.custom-navbar-text-thanks {
  display: inline;
  font-size: 0.9rem;
}

.btn-whatsapp_fixed {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 30px;
  right: 30px;
  background-color: #25d366;
  border-radius: 50px;
  font-size: 30px;
  z-index: 10000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}


@media (max-width: 767px) {
  .gold-bar{
    width: 350px !important;
  }

  .iframe-card-details {
    width: 20rem !important;  
    height: 18rem !important;
  }
  .custom-navbar-logo-container-thanks p{
    font-size: 0.8rem;
  }

  .logo-thanks-page {
    width: 80%;
  }
  .custom-navbar-container img {
    width: 120px !important;
  }

  .custom-navbar-container {
    width: 100% !important;
    border-radius: 0;
    padding: 1rem 1rem;
  }
  .custom-navbar {
    margin-top: 0rem;
    width: 100% !important;
  }

  .card-details {
    position: relative !important;
    margin: 0 1rem !important;
    margin-top: -30rem !important;
  }

  .float-element {
    display: none;
  }
  .cta-valor-cota {
    margin: 4rem 0 !important; 
  }
  .full-width-mobile {
    width: 100% !important;
  }

  .ticket-selection-btn{
    width: 6rem !important;
    min-height: 6rem !important;
    margin: 0 1rem;
  }
  .selected-tickets-counter button {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 2rem;
  }

  .selected-tickets-counter input {
    width: 70%;
  }
}

.numbers-container{
  max-height: 350px;
  overflow-y: scroll;
}

.iframe-card-home {
  width: 100% !important;
  height: 400px;
} 

.iframe-card-details {
  width: 100% !important;
  height: 315px;
} 

.numbers-reservados {
  background-color: #ffbf00 !important;
  padding: 0.3rem 0.7rem;
  color: #f7f7f7 !important;
  border-radius: 25px;
  text-align: center;
}

.numbers-ativos {
  background-color: #00ff4c !important;
  padding: 0.3rem 0.7rem;
  color: #f7f7f7 !important;
  border-radius: 25px;
  text-align: center;
}

.spinner-container{
  background-color: #f7f7f7;
  border-radius: 25px;
  color: #c20303;
  margin: 0rem 2rem;
  padding: 2rem 1rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600
}

.btn-auth{
  background-color: #c20303!important;
}

.spinner-container p {
  margin-top: 1rem;
}

.faq-bkg {
  background-image: url('../img/barra-ouro.svg');
  background-size: 250px 145px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.faq-component {
  opacity: 0.9;
}